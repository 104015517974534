import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Upload, Button, Checkbox, notification } from "antd";
import "./Enrollment.css";
import config from "../../../utils/server";

function Enrollment() {
  const [fileList, setFileList] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      form.setFieldValue("file_name", file.name);
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  function handleSubmitForm(v) {
    setIsButtonLoading(true);
    let formData = new FormData();
    delete v.fileList;

    console.log(v);
    formData.append("data", JSON.stringify(v));

    fileList.forEach((file) => {
      formData.append("file", file);
    });

    fetch(`${config.server_ip}/subscriber/create`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        res
          .json()
          .then((resJson) => {
            if (resJson.affectedRows && resJson.affectedRows === 2) {
              setTimeout(() => {
                setIsButtonLoading(false);
                setFileList([]);
                form.resetFields();
                notification.success({
                  message: "Atualizado!",
                  description: "Este e-mail já se encontrava registado. Por isso atualizamos os seus dados!",
                });
              }, 1500);
            } else {
              setTimeout(() => {
                setIsButtonLoading(false);
                setFileList([]);
                form.resetFields();
                notification.success({
                  message: "Sucesso!",
                  description: "Foi inscrito com sucesso no concurso de casos clínicos em neurologia!",
                });
              }, 1500);
            }
          })
          .catch((err) => {
            console.log(err);
            notification.error({
              message: "Erro!",
              description: "Aconteceu algum erro no servidor. Por favor tente novamente mais tarde.",
            });
            setIsButtonLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Erro!",
          description: "Aconteceu algum erro no servidor. Por favor tente novamente mais tarde.",
        });
        setIsButtonLoading(false);
      });
  }

  function handleFinishFailed(e) {
    console.log(e);
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="register">
      <Row className="content" align="center">
        <Col span={18}>
          <h1 className="title_page">INSCRIÇÃO NO CONCURSO</h1>
          <Form form={form} id="form_register" onFinish={handleSubmitForm} onFinishFailed={handleFinishFailed} className="form_register">
            <Form.Item
              labelCol={{ span: 24 }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Input size="large" placeholder="NOME*" />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="email"
              rules={[
                {
                  type: "email",
                  message: "E-mail não é válido",
                },
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Input size="large" placeholder="E-MAIL*" />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Input size="large" placeholder="TELEFONE*" />
            </Form.Item>
            <Form.Item labelCol={{ span: 24 }} name="work_location">
              <Input size="large" placeholder="LOCAL DE TRABALHO" />
            </Form.Item>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item labelCol={{ span: 24 }} name="order_name">
                  <Input size="large" placeholder="ORDEM DOS MÉDICOS" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item labelCol={{ span: 24 }} name="order_nr">
                  <Input size="large" placeholder="NÚMERO ORDEM" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Row className="file_row">
                  <Form.Item
                    className="upload_file_name"
                    name="file_name"
                    rules={[
                      {
                        required: true,
                        message: "Este campo é obrigatório",
                      },
                    ]}
                  >
                    <Input readOnly size="large" placeholder="ANEXAR FICHEIRO" />
                  </Form.Item>
                  <Form.Item className="upload_file" name="fileList" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload {...props}>
                      <div className="input_upload">
                        <div className="input_upload_button">ESCOLHER FICHEIRO</div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Row>
              </Col>
            </Row>

            <Form.Item
              name="consent"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Checkbox>Consentimento Preenchido*</Checkbox>
            </Form.Item>

            <p className="info_form">
              INFORMAÇÕES SOBRE PROTEÇÃO DE DADOS | Responsável: ZAMBON Produtos Farmacêuticos, Lda. | Finalidade: Notifique-o da confirmação do registo, lembrete e marcação do
              calendário do Webinar, mantenha, gira e controle a sua participação e acesso ao curso da Zambon. Gerir o inquérito de satisfação. | Legitimação: Consentimento da
              parte interessada. | Destinatários: Os seus dados serão comunicados à Zambon a fim de o registar para o webinar. Assim como as transferências legalmente exigidas |
              Direitos: Acesso, retificação e eliminação, assim como outros direitos cujos detalhes são fornecidos na informação adicional. | Informação adicional: Para mais
              informações sobre como Zambon irá tratar os seus dados pessoais, pode consultar a sua política de privacidade no seguinte link
            </p>

            <Form.Item
              name="accept_policy"
              valuePropName="checked"
              className="mb-0"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Checkbox>
                Li e aceito a <a>Política de Privacidade</a>*
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="accept_regulation"
              valuePropName="checked"
              className="mb-0"
              rules={[
                {
                  required: true,
                  message: "Este campo é obrigatório",
                },
              ]}
            >
              <Checkbox>
                Li e aceito o <a onClick={() => window.open("/regulamento")}>Regulamento de Participação</a> do curso*
              </Checkbox>
            </Form.Item>

            <Form.Item name="agreement" valuePropName="checked" className="mb-0">
              <Checkbox>Concordo em receber comunicações electrónicas com informações, nas minhas áreas de interesse, sobre os produtos, serviços e atividades da Zambon.</Checkbox>
            </Form.Item>

            <Button className="primary_button submit mt-20" onClick={form.submit} loading={isButtonLoading}>
              PARTICIPAR
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Enrollment;
