import { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, notification } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

import config from "../../../utils/server";

import loadingLogoAnimation from "../../../animations/data.json";
import imageLogin from "../../../assets/images/logo-hero.svg";

import "./Login.css";

function Login({ handleLogin }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    if (tokenData) {
      axios
        .post(`${config.server_ip}/auth/verifyToken`, {
          data: tokenData,
        })
        .then((res) => {
          console.log(res);
          return navigate("/admin/dashboard");
        })
        .catch((err) => {
          setTimeout(() => {
            console.log(err);
            setIsLoading(false);
          }, 2000);
        });
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, []);

  function handleSubmitForm(v) {
    setIsButtonLoading(true);

    axios
      .post(`${config.server_ip}/auth/login`, {
        data: v,
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("token", res.data.token);
        notification.success({
          message: "Login efetuado!",
          description: "Credenciais corretas, iremos dar reedirect para o nosso painel",
        });
        setTimeout(() => {
          setIsButtonLoading(false);
          form.resetFields();
          handleLogin(res.data.data);
          navigate("/admin/dashboard");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  return (
    <div className="login">
      <div className={`loading_page ${!isLoading ? "hidden" : ""}`}>
        <Lottie animationData={loadingLogoAnimation} />
      </div>
      <Row className="container" align="center">
        <Col span={16} className="login-div">
          <Row span={24} className="h-100">
            <Col span={12} className="img_login">
              <img src={imageLogin} />
            </Col>
            <Col span={12} className="form_login_div">
              <h1 className="title_page">LOGIN</h1>
              <Form form={form} id="form_login" onFinish={handleSubmitForm} className="form_login">
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input size="large" placeholder="USERNAME OU E-MAIL" />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Este campo é obrigatório",
                    },
                  ]}
                >
                  <Input size="large" type="password" placeholder="PASSWORD" />
                </Form.Item>

                <Form.Item noStyle>
                  <Button className="primary_button submit mt-20" onClick={form.submit} loading={isButtonLoading}>
                    LOGIN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
