import { Layout, Row, Col, Modal, Button, Form, Input, notification, Radio } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";

import config from "../../../utils/server";

import "./Update.css";

const Update = ({ data, open, close }) => {
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      delete data.password;
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleSubmit(values) {
    setIsLoadingButton(true);

    if (!values.password) {
      delete values.password;
    }

    axios
      .post(`${config.server_ip}/user/update`, {
        data: values,
      })
      .then((res) => {
        setTimeout(() => {
          if (!res.data.user_registered) {
            notification.error({
              message: "Utilizador existente",
              description: "Já existe um utilizador com esse e-mail, verifica novamente os dados inseridos",
            });
          } else {
            notification.success({
              message: "Utilizador adicionado",
              description: "Utilizador foi adicionado com sucesso. Já pode aceder ao painel com os respetivos dados.",
            });
            close();
            form.resetFields();
          }
          setIsLoadingButton(false);
        }, 1500);
      })
      .catch((err) => {
        setIsLoadingButton(false);
        console.log(err);
      });
  }

  function handleCancel() {
    console.log("cancel");
    close();
  }

  return (
    <Modal
      open={open}
      closable
      onOk={form.submit}
      onCancel={handleCancel}
      className="modal_add_user"
      title="Editar administrador"
      width={800}
      footer={[
        <Button key="back" size="large" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" size="large" loading={isLoadingButton} onClick={form.submit}>
          Editar
        </Button>,
      ]}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form form={form} id="form_add_user" onFinish={handleSubmit} className="form_add_user">
            <Form.Item
              labelCol={{ span: 24 }}
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                  message: "NOME",
                },
              ]}
            >
              <Input size="large" placeholder="Nome" />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="email"
              label="E-mail"
              rules={[
                {
                  required: true,
                  message: "EMAIL",
                },
              ]}
            >
              <Input size="large" placeholder="E-mail" />
            </Form.Item>
            <Form.Item labelCol={{ span: 24 }} name="password" label="Password" rules={[{ required: true, message: "Este campo é obrigatório" }]} hasFeedback>
              <Input.Password size="large" placeholder="Password" />
            </Form.Item>
            <Form.Item labelCol={{ span: 24 }} name="type" label="Tipo" rules={[{ required: true, message: "Este campo é obrigatório" }]} hasFeedback>
              <Radio.Group>
                <Radio value={1}>Administrador</Radio>
                <Radio value={2}>Gestor</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default Update;
