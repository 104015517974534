import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import axios from "axios";
import ReactGA from "react-ga4";

import Main from "./components/Main/Main";
import MainAdmin from "./components/MainAdmin/MainAdmin";
import AdminDashboard from "./pages/Admin/Dashboard/Dashboard";
import AdminSubscribers from "./pages/Admin/Subscribers/Subscribers";
import AdminFinalists from "./pages/Admin/Finalists/Finalists";
import AdminUsers from "./pages/Admin/Users/Users";

import Home from "./pages/Website/Home/Home";
import Login from "./pages/Website/Login/Login";
import config from "./utils/server";
import Regulation from "./pages/Website/Regulation/Regulation";
import PrivacyPolicy from "./pages/Website/PrivacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./pages/Website/CookiesPolicy/CookiesPolicy";
import Program from "./pages/Website/Program/Program";
import Enrollment from "./pages/Website/Enrollment/Enrollment";

import "./App.css";

const TRACKING_ID = "G-91799QYRG8"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    console.log(tokenData);
    if (tokenData) {
      axios
        .post(`${config.server_ip}/auth/verifyToken`, {
          data: tokenData,
        })
        .then((res) => {
          console.log(res);
          setUser(res.data.user_data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/programa",
          element: <Program />,
        },
        {
          path: "/inscricao",
          element: <Enrollment />,
        },
        {
          path: "/regulamento",
          element: <Regulation />,
        },
        {
          path: "/politica-de-privacidade",
          element: <PrivacyPolicy />,
        },
        {
          path: "/politica-de-cookies",
          element: <CookiesPolicy />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login handleLogin={handleLogin} />,
    },
    {
      path: "/admin/",
      element: <MainAdmin handleLogout={handleLogout} />,
      children: [
        {
          path: "/admin/dashboard",
          element: <AdminDashboard />,
        },
        {
          path: "/admin/subscribers",
          element: <AdminSubscribers />,
        },
        {
          path: "/admin/finalists",
          element: <AdminFinalists />,
        },
        {
          path: "/admin/users",
          element: <AdminUsers user={user} />,
        },
      ],
    },
  ]);

  function handleLogin(data) {
    setUser(data);
    setIsLoggedIn(true);
  }

  function handleLogout() {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#F39325",
        },
      }}
    >
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ConfigProvider>
  );
}

export default App;
