import { useState, useEffect } from "react";
import { Breadcrumb, Layout, Menu, theme, Row, Col, Form, Input, Upload, Button, Checkbox, notification, Table } from "antd";
import Search from "antd/es/input/Search";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import "./Users.css";
import Create from "../../../components/User/Create/Create";
import config from "../../../utils/server";
import Update from "../../../components/User/Update/Update";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Tipo",
    dataIndex: "type_name",
    key: "type_name",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
  },
];

function AdminUsers() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [user, setUser] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    if (tokenData) {
      axios
        .post(`${config.server_ip}/auth/verifyToken`, {
          data: tokenData,
        })
        .then((res) => {
          let userData = res.data.user_data;
          setUser(res.data.user_data);
          handleGetData(userData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function handleGetData(userData) {
    axios
      .get(`${config.server_ip}/user/read`)
      .then((res) => {
        let usersData = res.data;

        let aux = [];
        usersData.forEach((e) => {
          let auxObject = {
            name: e.name,
            email: e.email,
            type_name: e.type === 1 ? "Administrador" : "Gestor",
            actions: (
              <div>
                <Button className="mr-10" onClick={() => handleOpenUpdate(e)}>
                  <EditOutlined />
                </Button>
                {e.email !== "@master" && userData.type === 1 && (
                  <Button>
                    <DeleteOutlined />
                  </Button>
                )}
              </div>
            ),
          };
          aux.push(auxObject);
        });

        setData(aux);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleOpenUpdate(data) {
    console.log(data);
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseCreate() {
    handleGetData(user);
    setIsOpenCreate(false);
  }

  function handleCloseUpdate() {
    setSelectedData(null);
    setIsOpenUpdate(false);
  }

  return (
    <div className="admin_content">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Row className="content">
        <Col span={24}>
          <Row gutter={[24]}>
            <Col span={24}>
              <Row className="dash_card">
                <Col span={24}>
                  <div className="table_header">
                    <div className="title">
                      <h1>Administradores</h1>
                    </div>
                    {user.type === 1 && (
                      <Button size="large" onClick={handleOpenCreate}>
                        Adicionar
                      </Button>
                    )}
                  </div>
                  <Table dataSource={data} columns={columns} loading={isLoading} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AdminUsers;
